.footer {
    background-color: #8d0f0f;
    color: #ffffff;
    padding: 40px 0 20px;
    font-family: Arial, sans-serif;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-section {
    margin-bottom: 20px;
}

.footer-section h3 {
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 10px;
    font-family: "Merriweather", serif;
}

.footer-section p {
    font-size: 14px;
    line-height: 1.6;
}

.footer-bottom {
    text-align: center;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #a5a5a5;
}

.footer-bottom p {
    font-size: 12px;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        text-align: center;
        margin-bottom: 30px;
    }
}