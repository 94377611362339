.how-we-are-different-container {
    background-color: #e9e9e9;
    padding: 50px;
    margin-top: 30px;
    padding-left: 100px;
    padding-right: 100px;
}

.how-we-are-different-title {
    font-family: "Merriweather", serif;
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 10px;
    margin-top: 0px;
    text-align: center;
}

.marroon-divider {
    width: 250px;
    height: 2px;
    background-color: #8d0f0f;
    margin-top: 5px;
    margin-bottom: 10px;
}
.divider-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.how-we-are-different-container ol {
    padding-left: 20px;  /* Adjusts the left padding of the entire list */
}

.how-we-are-different-container li {
    margin-bottom: 20px;  /* Adds space between list items */
    font-family: "Merriweather", serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 1.6;     /* Improves line spacing within each list item */
}

.how-we-are-different-container li:last-child {
    margin-bottom: 0;  /* Removes extra space after the last item */
}

.fade-element {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  .fade-element.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Add some delay to stagger the animations */
  .how-we-are-different-container ol li:nth-child(1) {
    transition-delay: 0.1s;
  }
  
  .how-we-are-different-container ol li:nth-child(2) {
    transition-delay: 0.2s;
  }
  
  .how-we-are-different-container ol li:nth-child(3) {
    transition-delay: 0.3s;
  }

@media (max-width: 768px) {
    .how-we-are-different-container {
        padding: 30px;
        padding-left: 40px;
        padding-right: 20px;
    }

    .how-we-are-different-container ol {
        padding-left: 0;  /* Resets the left padding of the entire list */
    }

    .how-we-are-different-container li {
        font-size: 16px;
    }
}