.registration-container {
    /* background-color: #f5f5f5; */
    padding: 40px;
    margin-bottom: 30px;
}

.registration-title {
    font-family: "Merriweather", serif;
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 10px;
    text-align: center;
}

.registration-divider-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.registration-divider {
    width: 250px;
    height: 2px;
    background-color: #8d0f0f;
}

.registration-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.registration-content p {
    margin-bottom: 20px;
    line-height: 1.6;
}

.interest-button {
    display: inline-block;
    background-color: #8d0f0f;
    color: #ffffff;
    font-weight: bold;
    text-decoration: none;
    padding: 15px 30px;
    border-radius: 5px;
    font-size: 18px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.interest-button:hover {
    background-color: #6d0c0c;
    transform: scale(1.05);
}

.interest-button:active {
    transform: scale(0.98);
}

@media (max-width: 768px) {
    .registration-container {
        padding: 30px 20px;
    }

    .registration-title {
        font-size: 24px;
    }

    .interest-button {
        padding: 12px 24px;
        font-size: 16px;
    }
}

.fade-element {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  .fade-element.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Add some delay to stagger the animations */
  .registration-content p:nth-child(1) {
    transition-delay: 0.1s;
  }
  
  .registration-content p:nth-child(2) {
    transition-delay: 0.2s;
  }
  
  .registration-content .interest-button {
    transition-delay: 0.3s;
  }