.about-container {
    width: 100%;
    display: flex;
}

.about-img {
    width: 100%;
    border-radius: 10px;
    /* margin: 20px; */
    /* margin-left: 40px; */
}

.about-img-container {
    flex-shrink: 0;
    width: 30%;
    overflow: hidden;
    padding: 20px;
    padding-left: 20px;
}

.about-text-div {
    padding-left: 20px;
}

.marroon-divider {
    width: 250px;
    height: 2px;
    background-color: #8d0f0f;
}

.about-title {
    font-family: "Merriweather", serif;
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 10px;
}

.about-text {
    padding-right: 40px;
    font-family: "Merriweather", serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
}

.fade-element {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  .fade-element.fade-in {
    opacity: 1;
    transform: translateY(0);
  }

  #about-mobile-header {
    display: none;
}

#about-desktop-header {
    display: block;
    /* width: 100%; */
}

#about-divider-desktop {
    display: block;
}

#about-divider-mobile {
    display: none;
}


@media (max-width: 768px) {
    .about-container {
        flex-direction: column;
        box-sizing: border-box;
        padding: 20px;
    }

    #about-mobile-header {
        display: block;
    }

    #about-desktop-header {
        display: none;
        /* width: 100%; */
    }

    #about-divider-desktop {
        display: none;
    }

    #about-divider-mobile {
        display: block;
        margin-bottom: 20px;
    }


    .about-img {
        width: 100%;
        margin: 0px;
    }

    .about-img-container {
        width: 100%;
        padding: 0px;
    }

    .about-text-div {
        padding-left: 0;
    }

    .about-text {
        padding-right: 0;
    }
}