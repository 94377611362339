.faq-container {
    /* background-color: #e9e9e9; */
    padding-top: 40px;
    padding-bottom: 80px;
    margin-top: 30px;
    width: 80%;
    margin-left: 10%;  
}

.faq-title {
    font-family: "Merriweather", serif;
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 10px;
    margin-top: 0px;
    text-align: center;
}

.faq-divider-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.faq-divider {
    width: 250px;
    height: 2px;
    background-color: #8d0f0f;
    margin-top: 5px;
    margin-bottom: 10px;
}

.faq-list {
    margin-top: 20px;
}

.faq-item {
    border-bottom: 1px solid #cccccc;
    padding: 15px 0;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    cursor: pointer;
}

.faq-icon {
    color: #8d0f0f;
}

.faq-answer {
    margin-top: 10px;
    color: #666666;
    line-height: 1.6;
}